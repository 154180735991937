import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styles from './Contact.module.css';
import ContactForm from './ContactForm';
import Map from '../UI/Map';

const Contact = () => {
  return (
    <Container fluid>
      <Row className={styles.contact}>
        <Col className={styles.contact_left} md={6}>
          <h1 className={`pt-3 ${styles.contact_left_h1}`}>Kontakt</h1>
          <p className={`pt-3 ${styles.contact_left_p}`}>
            Imate pitanja, slobodno nas kontaktirajte
          </p>
          <div className='dbox w-100 d-flex align-items-start pt-3'>
            <span
              className={`fa fa-map-marker m-2 ${styles.contact_left_span}`}
            ></span>
            <p className={`m-2 mb-3 ${styles.contact_left_p}`}>
              <span>21000 Novi Sad</span>, Pavla Papa 18
            </p>
          </div>
          <div className='dbox w-100 d-flex align-items-start'>
            <span
              className={`fa fa-phone m-2 ${styles.contact_left_span}`}
            ></span>
            <p className={`m-2 mb-3 ${styles.contact_left_p}`}>
              <span>Telefon: </span>
              <a href='tel://123456789'>+381 63 565 305</a>
            </p>
          </div>
          <div className='dbox w-100 d-flex align-items-start'>
            <span
              className={`fa fa-paper-plane m-2 ${styles.contact_left_span}`}
            ></span>
            <p className={`m-2 mb-3 ${styles.contact_left_p}`}>
              <span>Email: </span>
              <a href='mailto:info@yoursite.com'>office@impexmetal.rs</a>
            </p>
          </div>
          <div className='dbox w-100 d-flex align-items-start'>
            <span
              className={`fa fa-globe m-2 ${styles.contact_left_span}`}
            ></span>
            <p className={`m-2 mb-3 ${styles.contact_left_p}`}>
              <span>Website: </span>
              <a href='/'>www.impexmetal.rs</a>
            </p>
          </div>
        </Col>
        <Col className={styles.contact_right} md={6}>
          <h1 className={styles.contact_h1}>Kontaktirajte nas</h1>
          <ContactForm />
        </Col>
        <Col className='mt-5'>
          <Map className={styles.map} />
        </Col>
      </Row>
    </Container>
  );
};

export default Contact;
