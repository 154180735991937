import React from 'react';
import styles from './Services.module.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import { Link } from 'react-router-dom';
import calc from '../images/calc.jpg';
import laptop from '../images/laptop.jpg';
import business2 from '../images/business2.jpg';
import money from '../images/money.jpg';

const Services = () => {
  return (
    <Container fluid className={`my-5 ${styles.services}`}>
      <h1 className={`my-5 ${styles.services_h1}`}>Usluge</h1>
      <Row className={`m-lg-5 px-3 ${styles.services_row}`}>
        <Col lg={6} className={styles.services_col} data-aos='fade-up'>
          <h3 className={styles.services_h3}>Knjigovodstvo</h3>
          <p className={`text-center ${styles.services_text}`}>
            Obavljamo knjiženje dokumentacije, mesečno finansijsko izveštavanje,
            vršenje usluga platnog prometa, sastavljanje probnih bilansa.
            <br />
            Knjiženje poslovne dokumentacije u skladu sa zakonom o
            računovodstvu, MSFI, pravilnikom za mikro i druga pravna lica,
            zakonom o porezu na dohodak građana, ostalim zakonima, uredbama,
            pravilnicima i propisima koji regulišu računovodstvo. Vođenje
            analitičkih evidencija: registar OS, amortizacija na godišnjem
            nivou, kupci, dobavljači. Evidencija kredita i lizinga, otplata
            glavnice na rate, troškovi kamata. Knjiženje dinarskih i deviznih
            izvoda (platni promet).
            <br />
            <Link
              to='/contact'
              style={{ color: 'danger', textDecoration: 'none' }}
            >
              <strong className={styles.services_strong}>
                Kontaktirajte nas
              </strong>
            </Link>
          </p>
        </Col>
        <Col lg={6} data-aos='fade-down'>
          <Image src={calc} fluid width={600} rounded />
        </Col>
      </Row>
      <Row className={`m-lg-5 px-3 ${styles.services_row}`}>
        <Col lg={6} className='order-2' data-aos='fade-down'>
          <Image src={laptop} fluid width={600} rounded />
        </Col>
        <Col
          lg={6}
          className={`order-lg-2 ${styles.services_col}`}
          data-aos='fade-up'
        >
          <h3 className={styles.services_h3}>Računovodstvo</h3>
          <p className={`text-center ${styles.services_text}`}>
            Impexmetal knjigovodstvo, kao svoju osnovnu delatnost, sa preko 30
            godina postojanja, nudi Vam kompletne
            knjigovodstveno-računovodstvene poslove na jednom mestu.
            <br />
            Možemo Vam ponuditi izradu arhivske knjige i prijave arhivske
            gradje, kao i izradu pravilnika po kojem posluje Vaše privredno
            društvo, a koji ste u obavezi da prijavite istoriskoj arhivi. Izrada
            se vrši na godišnjem nivodu kao jednokratna usluga. Prikupljamo i
            organizujemo svu neophodnu dokumentaciju za završni račun Vaše firme
            ili organizacije na kraju godine koju dostavljamo svim relevantnim
            nadležnim organima.
            <br />
            Obavljamo sve vrste obračuna poreza, bilo da ste fizičko ili pravno
            lice. Ne razmišljajte o neplaćenim obavezama, mi ćemo regulisati
            Vaše poreske obaveze pedantno i na vreme.
            <br />
            <Link
              to='/contact'
              style={{ color: 'danger', textDecoration: 'none' }}
            >
              <strong className={styles.services_strong}>
                Kontaktirajte nas
              </strong>
            </Link>
          </p>
        </Col>
      </Row>
      <Row className={`m-lg-5 px-3 ${styles.services_row}`}>
        <Col lg={6} className={styles.services_col} data-aos='fade-up'>
          <h3 className={styles.services_h3}>Savetovanje</h3>
          <p className={`text-center ${styles.services_text}`}>
            Ukoliko želite da osnujete preduzeće, radnju, udruženje gradjana ili
            izvršite neke promene u istim, potrebna vam je izrada ugovora,
            odluka, formulara i slično, sve te informacije možete dobiti od nas.
            <br />
            Bilo da Vam je potrebno da se preda određena poreska prijava ili da
            se piše dopis ili žalba Poreskoj upravi možete nam se obratiti.
            <br />
            Vršimo izradu i pripremu kompletne dokumentacije potrebne za
            podnošenje kredita kod Vaše poslovne banke. Radi lakšeg i
            efikasnijeg poslovanja, kreiramo ugovore sa fizičkim i pravnim
            licima u skladu sa svim relevantnim zakonima i potrebama Vaše firme.
            <br />
            <Link
              to='/contact'
              style={{ color: 'danger', textDecoration: 'none' }}
            >
              <strong className={styles.services_strong}>
                Kontaktirajte nas
              </strong>
            </Link>
          </p>
        </Col>
        <Col lg={6} data-aos='fade-down'>
          <Image src={business2} fluid width={600} rounded />
        </Col>
      </Row>
      <Row className={`m-lg-5  px-3 ${styles.services_row}`}>
        <Col
          lg={6}
          className={`order-2 ${styles.services_col}`}
          data-aos='fade-down'
        >
          <Image src={money} fluid width={600} rounded />
        </Col>
        <Col
          lg={6}
          className={`order-lg-2 ${styles.services_col}`}
          data-aos='fade-up'
        >
          <h3 className={styles.services_h3}>Pravni poslovi</h3>
          <p className={`text-center ${styles.services_text}`}>
            U skladu sa sferom Vašeg poslovanja, pripremamo i predajemo potrebnu
            dokumentaciju za prijavljivanje ili odjavljivanje radnika.
            <br />
            Obračun zarada, obračun poreza na dodatu vrednost, izrada i
            podnošenje POPDV poreske prijave. Obračun poreza na dobit preduzeća,
            obračun poreza na zakup pokretne i nepokretne imovine. Vođenje
            poreskih evidencija. Komunikacija sa nadležnim poreskim
            institucijama. Završni finansijski izveštaji: bilans stanja, bilans
            uspeha, statistički aneks, poreski bilans itd.
            <br />
            <Link
              to='/contact'
              style={{ color: 'danger', textDecoration: 'none' }}
            >
              <strong className={styles.services_strong}>
                Kontaktirajte nas
              </strong>
            </Link>
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default Services;
