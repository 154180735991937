import React from 'react';
import office from '../images/office.jpg';
import office1 from '../images/business1.jpg';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styles from './About.module.css';
import Container from 'react-bootstrap/Container';
import { Link } from 'react-router-dom';

const About = () => {
  return (
    <Container
      fluid='md'
      id='about'
      className={`px-5 ${styles.about_container}`}
    >
      <h1 className={styles.about_h1}>O nama</h1>
      <Row className='mt-5'>
        <Col
          xl
          lg={6}
          className={`left order-2 ${styles.about_text}`}
          data-aos='fade-right'
        >
          <p>
            <Link className={styles.about_p1} to='/contact'>
              <strong className={styles.about_p1}>Impexmetal</strong>
            </Link>{' '}
            je knjigovodstvena agencija koja je osnovana od strane experata pre
            više od 30 godina.
          </p>
          <p>
            Pružamo usluge knjigovodstva, računovodstva i poreskog savetovanja.
            Bilo da ste preduzetnik, fizičko ili pravno lice postanite naš
            partner i budite sigurnim rukama.
          </p>
          <p className={styles.about_p}>
            Kada postanete naš dragoceni klijent, neće biti nikakvih skrivenih
            troškova i neprijatnih iznenađenja. Razlog za to je kada se unapred
            jedno dogovorimo u vezi fiksnih mesečnih nadoknada, čvrsto se
            pridržavamo toga. Sve knjigovodstvene usluge koje nudimo se rade od
            strane našeg stručnog tima, uklanjajući vaš stres na taj način. Kada
            se sastanemo sa vama, u razgovoru ćemo otkriti oblasti gde vam
            možemo najviše pomoći. U dogovoru sa vama izabraćemo paket usluga
            koji najviše odgovaraju vašim potrebama, i sve to uz fer mesečnu
            nadoknadu. Provešćemo vas kroz svaki korak u procesu, od
            prikupljanja relevantnih informacija, do pripreme poreskih prijava i
            njihovih predaja u zakonskim rokovima. Na početku svake poslovne
            godine, obavestićemo Vas koje su vaše obaveze prema državnim
            organima i uspostavićemo sistem tako da više ne brinete o rokovima,
            zateznim kamatama i kaznama.
          </p>
        </Col>
        <Col
          xl
          lg={6}
          className={`right order-lg-2 ${styles.about_images}`}
          data-aos='fade-left'
        >
          <Image src={office} alt='office cardboard' className={styles.img} />
          <Image src={office1} alt='office cardboard' className={styles.img2} />
        </Col>
      </Row>
    </Container>
  );
};

export default About;
