import { Fragment, useState } from 'react';
import useInput from '../hooks/use-input';
import emailjs from '@emailjs/browser';
import Modals from '../UI/Modals';
import Form from 'react-bootstrap/Form';
import styles from './ContactForm.module.css';

const isNotEmpty = (value) => value.trim() !== '';
const isEmail = (value) => value.includes('@');

const ContactForm = (props) => {
  const {
    value: enteredName,
    isValid: enteredNameIsValid,
    hasError: nameHasError,
    valueChangeHandler: nameChangedHandler,
    inputBlurHandler: nameBlurHandler,
    reset: resetName,
  } = useInput(isNotEmpty);

  const {
    value: enteredEmail,
    isValid: enteredEmailIsValid,
    hasError: emailHasError,
    valueChangeHandler: emailChangedHandler,
    inputBlurHandler: emailBlurHandler,
    reset: resetEmail,
  } = useInput(isEmail);

  const {
    value: enteredSubject,
    isValid: enteredSubjectIsValid,
    hasError: subjectHasError,
    valueChangeHandler: subjectChangedHandler,
    inputBlurHandler: subjectBlurHandler,
    reset: resetSubject,
  } = useInput(isNotEmpty);

  const {
    value: enteredMessage,
    isValid: enteredMessageIsValid,
    hasError: messageHasError,
    valueChangeHandler: messageChangedHandler,
    inputBlurHandler: messageBlurHandler,
    reset: resetMessage,
  } = useInput(isNotEmpty);

  const nameClasses = nameHasError ? 'form-control invalid' : 'form-control';
  const emailClasses = emailHasError ? 'form-control invalid' : 'form-control';
  const subjectClasses = subjectHasError
    ? 'form-control invalid'
    : 'form-control';
  const messageClasses = messageHasError
    ? 'form-control invalid'
    : 'form-control';

  const templateParams = {
    name: enteredName,
    to_name: 'Impexmetal',
    subject: enteredSubject,
    message: enteredMessage,
    reply_to: enteredEmail,
  };

  let formIsValid = false;

  if (
    enteredNameIsValid &&
    enteredEmailIsValid &&
    enteredSubjectIsValid &&
    enteredMessageIsValid
  ) {
    formIsValid = true;
  }
  const [modalShow, setModalShow] = useState(false);

  const submitHandler = (e) => {
    e.preventDefault();

    if (!formIsValid) {
      return;
    }
    emailjs
      .send(
        'service_7wwi8ih',
        'template_86icevo',
        templateParams,
        'O7SQC6v0rfvjEb8_l'
      )
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
      })
      .catch((err) => {
        console.log('FAILED...', err);
      });
    setModalShow(true);

    resetName();
    resetEmail();
    resetSubject();
    resetMessage();
  };

  return (
    <Fragment>
      <Modals show={modalShow} onHide={() => setModalShow(false)} />
      <Form onSubmit={submitHandler}>
        <Form.Group>
          <Form.Control
            className={`${nameClasses} ${styles.form_control}`}
            type='text'
            name='name'
            value={enteredName}
            onChange={nameChangedHandler}
            onBlur={nameBlurHandler}
            placeholder='Ime i prezime / Naziv firme'
            required
          />
          {nameHasError && (
            <p className='text-danger'>Upišite ime i naziv firme!</p>
          )}
        </Form.Group>
        <Form.Group>
          <Form.Control
            className={`${emailClasses} ${styles.form_control}`}
            type='text'
            name='reply_to'
            value={enteredEmail}
            onChange={emailChangedHandler}
            onBlur={emailBlurHandler}
            placeholder='E-mail:'
            required
          />
          {emailHasError && <p className='text-danger'>Upišite email!</p>}
        </Form.Group>
        <Form.Group>
          <Form.Control
            className={`${subjectClasses} ${styles.form_control}`}
            type='text'
            name='subject'
            value={enteredSubject}
            onChange={subjectChangedHandler}
            onBlur={subjectBlurHandler}
            placeholder='Predmet'
            required
          />
          {subjectHasError && <p className='text-danger'>Upišite predmet!</p>}
        </Form.Group>
        <Form.Group>
          <Form.Control
            className={`${messageClasses} ${styles.form_control} ${styles.textarea}`}
            as='textarea'
            name='message'
            value={enteredMessage}
            onChange={messageChangedHandler}
            onBlur={messageBlurHandler}
            id='message'
            cols={30}
            rows={8}
            placeholder='Upišite poruku'
            required
          />
          {messageHasError && <p className='text-danger'>Upišite poruku!</p>}
        </Form.Group>
        <button
          className={`btn ${styles.btn_contact}`}
          onClick={submitHandler}
          disabled={!formIsValid}
          type='submit'
        >
          Pošalji poruku
        </button>
      </Form>
    </Fragment>
  );
};

export default ContactForm;
