import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import NavLink from 'react-bootstrap/NavLink';
import Navbar from 'react-bootstrap/Navbar';
import FigureImage from 'react-bootstrap/FigureImage';
import logo from '../images/impexT.png';
import styles from './Header.module.css';
import { Link } from 'react-router-dom';

const Header = () => {
  const [navbar, setNavbar] = useState(false);
  const [navLogo, setNavLogo] = useState(logo);

  const changeBackground = () => {
    if (window.scrollY >= 80) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  useEffect(() => {
    changeBackground();
    window.addEventListener('scroll', changeBackground);
  });

  const changeLogo = () => {
    if (window.scrollY >= 80) {
      setNavLogo(logo);
    } else {
      setNavLogo(logo);
    }
  };

  useEffect(() => {
    changeLogo();
    window.addEventListener('scroll', changeLogo);
  });

  return (
    <div>
      <Navbar
        collapseOnSelect
        fixed='top'
        expand='md'
        bg='none'
        variant='dark'
        className={navbar ? `${styles.navbar_active}` : `${styles.navbar}`}
      >
        <Container fluid>
          <Link to='/'>
            <FigureImage
              className={styles.logo_img}
              src={navLogo}
              alt='logo'
              width='300px'
            />
          </Link>
          <Navbar.Toggle
            aria-controls='responsive-navbar-nav'
            className={styles.toggle_btn}
          >
            <span role='button'>
              <i
                className='fa fa-bars'
                aria-hidden='true'
                style={{ color: '#f20808' }}
              ></i>
            </span>
          </Navbar.Toggle>
          <Navbar.Collapse
            id='responsive-navbar-nav'
            className={`me-auto ${styles.nav_collapse}`}
          >
            <Nav>
              <NavLink
                className={`nav-link ${styles.nav_links}`}
                eventKey='1'
                as={Link}
                to='/'
              >
                Početna
              </NavLink>
              <NavLink
                className={`nav-link ${styles.nav_links}`}
                eventKey='2'
                as={Link}
                to='/about'
              >
                O nama
              </NavLink>
              <NavLink
                className={`nav-link ${styles.nav_links}`}
                eventKey='3'
                as={Link}
                to='/services'
              >
                Usluge
              </NavLink>
              <Nav.Link
                className={`nav-link ${styles.nav_links}`}
                eventKey='4'
                as={Link}
                to='/contact'
              >
                Kontakt
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default Header;
