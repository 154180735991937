import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import icon from '../images/accounting.png';
import icon2 from '../images/documents.png';
import icon3 from '../images/acc-book.png';
import icon4 from '../images/consulting.png';
import icon5 from '../images/bookkeeping.png';
import icon6 from '../images/bookk.png';
import icon7 from '../images/bookkeep.png';
import icon8 from '../images/accountant.png';
import icon9 from '../images/finance.png';
import styles from './Home.module.css';
import { Link } from 'react-router-dom';
import { BsCheckLg } from 'react-icons/bs';

const Home = () => {
  return (
    <>
      <div id='home' className={styles.bg}>
        <Row className={styles.home}>
          <Col>
            <h1 className={styles.home_h1}>Fokusirajte se na posao</h1>
            <h1 className={styles.home_h1}>
              Administraciju i računovodstvo prepustite nama
            </h1>{' '}
            <div className={styles.home_btn}>
              <Link className={styles.h_btn} to='/contact'>
                Zatražite savetovanje
              </Link>
            </div>
          </Col>
        </Row>
      </div>
      {/* CARDS */}
      <Container fluid>
        <h1 className={styles.service_info_h1}>Usluge koje nudimo</h1>
        <Row className={styles.cards_row}>
          <Col lg md={6} className={`${styles.cards_col}`}>
            <Card className={styles.service_info_card} as={Link} to='/contact'>
              <Card.Body>
                <Image
                  className={`ms-3 ${styles.img}`}
                  src={icon}
                  width={100}
                />
                <Card.Title className={styles.card_title}>
                  Knjigovodstvo
                </Card.Title>
                <Card.Text className={styles.card_text}>
                  Obavljamo knjiženje dokumentacije, mesečno finansijsko
                  izveštavanje, vršenje usluga platnog prometa, sastavljanje
                  probnih bilansa.
                </Card.Text>
              </Card.Body>
            </Card>
            <Card className={styles.service_info_card} as={Link} to='/contact'>
              <Card.Body>
                <Image
                  className={`ms-3 ${styles.img}`}
                  src={icon3}
                  width={100}
                />
                <Card.Title className={styles.card_title}>
                  Računovodstvo
                </Card.Title>
                <Card.Text className={styles.card_text}>
                  Nudimo Vam kompletne knjigovodstveno-računovodstvene poslove
                  na jednom mestu. Bilo da ste malo ili veliko preduzeće.
                </Card.Text>
              </Card.Body>
            </Card>
            <Card className={styles.service_info_card} as={Link} to='/contact'>
              <Card.Body>
                <Image
                  className={`ms-3 ${styles.img}`}
                  src={icon2}
                  width={100}
                />
                <Card.Title className={styles.card_title}>
                  Prijave i odjave radnika
                </Card.Title>
                <Card.Text className={styles.card_text}>
                  U skladu sa sferom Vašeg poslovanja, pripremamo i predajemo
                  potrebnu dokumentaciju za prijavljivanje ili odjavljivanje
                  radnika.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col lg md={6} className={`${styles.cards_col}`}>
            <Card className={styles.service_info_card} as={Link} to='/contact'>
              <Card.Body>
                <Image
                  className={`ms-3 ${styles.img}`}
                  src={icon5}
                  width={100}
                />
                <Card.Title className={styles.card_title}>
                  Predaja poreskih prijava
                </Card.Title>
                <Card.Text className={styles.card_text}>
                  Obavljamo pripremu i podnošenje svih vrsta poreskih prijava
                  nadležnim poreskim organima, sve vaše obaveze će na vreme biti
                  izmirene.
                </Card.Text>
              </Card.Body>
            </Card>
            <Card className={styles.service_info_card} as={Link} to='/contact'>
              <Card.Body>
                <Image
                  className={`ms-3 ${styles.img}`}
                  src={icon4}
                  width={100}
                />
                <Card.Title className={styles.card_title}>
                  Poresko savetovanje
                </Card.Title>
                <Card.Text className={styles.card_text}>
                  Upoznajemo Vas sa svim Vašim poreskim obavezama i olakšicama.
                  Unapred procenjujemo i planiramo Vaše poreske obaveze, kako bi
                  ste ih na vreme ispunili.
                </Card.Text>
              </Card.Body>
            </Card>
            <Card className={styles.service_info_card} as={Link} to='/contact'>
              <Card.Body>
                <Image
                  className={`ms-3 ${styles.img}`}
                  src={icon6}
                  width={100}
                />
                <Card.Title className={styles.card_title}>
                  Sastavljanje ugovora
                </Card.Title>
                <Card.Text className={styles.card_text}>
                  Radi lakšeg i efikasnijeg poslovanja, kreiramo ugovore sa
                  fizičkim i pravnim licima u skladu sa svim relevantnim
                  zakonima i potrebama Vaše firme.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col lg md={6} className={`${styles.cards_col}`}>
            <Card className={styles.service_info_card} as={Link} to='/contact'>
              <Card.Body>
                <Image
                  className={`ms-3 ${styles.img}`}
                  src={icon7}
                  width={100}
                />
                <Card.Title className={styles.card_title}>
                  Predaja završnih računa
                </Card.Title>
                <Card.Text className={styles.card_text}>
                  Prikupljamo i organizujemo svu neophodnu dokumentaciju za
                  završni račun Vaše firme na kraju godine, koju dostavljamo
                  svim relevantnim nadležnim organima.
                </Card.Text>
              </Card.Body>
            </Card>
            <Card className={styles.service_info_card} as={Link} to='/contact'>
              <Card.Body>
                <Image
                  className={`ms-3 ${styles.img}`}
                  src={icon8}
                  width={100}
                />
                <Card.Title className={styles.card_title}>
                  Sve vrste obračuna poreza
                </Card.Title>
                <Card.Text className={styles.card_text}>
                  Bilo da ste fizičko ili pravno lice, ne razmišljajte o
                  neplaćenim obavezama. Mi ćemo pratiti vaše poreske obaveze i
                  obaveštavati vas pedantno i na vreme.
                </Card.Text>
              </Card.Body>
            </Card>
            <Card className={styles.service_info_card} as={Link} to='/contact'>
              <Card.Body>
                <Image
                  className={`ms-3 ${styles.img}`}
                  src={icon9}
                  width={100}
                />
                <Card.Title className={styles.card_title}>
                  Pravni poslovi
                </Card.Title>
                <Card.Text className={styles.card_text}>
                  Ukoliko želite da osnujete preduzeće, radnju, udruženje
                  gradjana ili izvršite neke promene u istim, potrebna vam je
                  izrada ugovora, odluka, formulara i slično.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        {/* WHY US */}
      </Container>
      <div className={styles.bg_why}>
        <div className={styles.why_text}>
          <h3 className={styles.why_h3}>Zašto mi?</h3>
          <p>
            <BsCheckLg /> Preko 30 godina iskustva...
          </p>
          <p>
            <BsCheckLg /> Plaćate ono što smo se dogovorili.
          </p>
          <p>
            <BsCheckLg /> Uštedu novca i vremena na poslovnim troškovima.
          </p>
          <p>
            <BsCheckLg /> Svi vaši važni poslovni podaci udaljeni su od vas na
            jedan poziv.
          </p>
          <p>
            <BsCheckLg /> Mi pratimo izmene poreskih pravila i zakona, tako da
            vi ne morate.
          </p>
          <div className={styles.home_btn}>
            <Link className={styles.h_btn} to='/contact'>
              Kontaktirajte nas
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
