import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Footer.module.css';
import logo from '../images/impexT.png';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const Footer = () => {
  return (
    <footer
      className={`footer-container text-center text-lg-start text-muted pt-2 ${styles.bg}`}
    >
      <Container fluid className='text-center text-md-start mt-5'>
        <Row className={`row pe-3 my-3 ${styles.footer_row}`}>
          <Col lg={4} className='mx-auto mb-4'>
            <div className='text-center'>
              <img
                className={`${styles.logo}`}
                src={logo}
                width='280'
                height='100'
                alt='Logo'
              />
            </div>
            <ul
              className={`me-4 d-flex flex-column text-center ${styles.list_footer}`}
            >
              <li>Broj žiro računa: 150-1859803-72</li>
              <li>PIB: 100235763</li>
              <li>Maticni broj: 08192367</li>
            </ul>
          </Col>
          <Col lg={2} className='ms-2 mx-auto my-2 mb-4 text-center'>
            <p>
              <Link to='about' className='text-reset text-decoration-none'>
                O nama
              </Link>
            </p>
            <p>
              <Link to='/services' className='text-reset text-decoration-none'>
                Usluge
              </Link>
            </p>
            <p>
              <Link to='/contact' className='text-reset text-decoration-none'>
                Kontakt
              </Link>
            </p>
            <p>
              <Link to='/contact' className='text-reset text-decoration-none'>
                Gde smo
              </Link>
            </p>
          </Col>
          <Col lg={4} className='ms-2 mx-auto my-2 mb-md-0 mb-4 text-center'>
            <p className={styles.contact_p}>
              <i className='fa fa-home me-3'></i> 21000 Novi Sad, Pavla Papa 18
            </p>
            <p className={styles.contact_p}>
              <i className='fa fa-envelope me-3'></i> office@impexmetal.rs
            </p>
            <p className={styles.contact_p}>
              <i className='fa fa-phone me-3'></i> +381 63 565 305
            </p>
            <div className={styles.footer_social}>
              <a href='/' className='me-3 text-reset'>
                <i className='fa fa-facebook-f'></i>
              </a>
              <a href='/' className='me-3 text-reset'>
                <i className='fa fa-instagram'></i>
              </a>
              <a href='/' className='me-3 text-reset'>
                <i className='fa fa-linkedin'></i>
              </a>
            </div>
          </Col>
        </Row>
      </Container>
      {/* COPYRIGHT */}
      <div className={`text-center p-5 ${styles.copyright}`}>
        <div className='mb-3'>
          © 2022 Copyright:
          <a
            className={`text-reset fw-bold me-3 ${styles.copyright_link}`}
            href='https://vecanski.co.rs/'
          >
            impexmetal.rs
          </a>
          <span> Developed by: </span>
          <a
            href='https://vladimirvecanski.netlify.app/'
            target='_blank'
            rel='noreferrer'
            className={`text-reset fw-bold ${styles.copyright_link}`}
          >
            Vladimir Vecanski
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
